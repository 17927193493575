import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import TechStack from "../components/CryptoToken/TechStack";
import UserFriendly from "../components/Cryptoexchange/UserFriendly";
import FaqSection from '../components/CryptoToken/FaqSection';
import BannerCrypto from '../components/CryptoToken/Banner';
import Whatis from '../components/CryptoToken/Whatis';
import DevProcess from '../components/CryptoToken/DevProcess';
import BenifitsOf from '../components/CryptoToken/BenifitsOf';
import Popular from '../components/CryptoToken/Popular';
import Exemplary from '../components/CryptoToken/Exemplary';
import WhyChoose from '../components/CryptoToken/WhyChoose';
import OurVarious from '../components/CryptoToken/OurVarious';
import TokenStandard from '../components/CryptoToken/TokenStandard';
import DevService from '../components/CryptoToken/DevService';
import ValueAdded from '../components/CryptoToken/ValueAdded';
// import DevelopToken from '../components/CryptoToken/DevelopToken';
import SecurityConsider from '../components/CryptoToken/SecurityConsider';
// import Compliance from '../components/CryptoToken/Compliance';
// import SmartCont from '../components/CryptoToken/SmartCont';
// import DecentralFinance from '../components/CryptoToken/DecentralFinance';
import TypesOf from '../components/CryptoToken/TypesOf';


  
const CryptoToken = () => (
  <section className="cryptoex">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/crypto-token-development/" />
        <title>Leading Crypto Token Development Company | Coinsclone</title>
        <meta name="description" content="Coinsclone is a Leading Crypto Token development company, we offers best token development services to create tokens on various blockchain platforms like BNB chain, Ethereum, Tron, etc" />
        <meta name="keywords" content="Crypto Token Development Company, Crypto Token Development Services"/>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Leading Crypto Token Development Company | Coinsclone" />
        <meta property="og:description" content="Coinsclone is a Leading Crypto Token development company, we offers best token development services to create tokens on various blockchain platforms like BNB chain, Ethereum, Tron, etc" />
        <meta property="og:url" content="https://www.coinsclone.com/crypto-token-development/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/crypto-token/crypto-token-development.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone is a Leading Crypto Token development company, we offers best token development services to create tokens on various blockchain platforms like BNB chain, Ethereum, Tron, etc" />
        <meta name="twitter:title" content="Leading Crypto Token Development Company | Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/crypto-token/crypto-token-development.png" />
      </Helmet>
      
      <BannerCrypto />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Crypto Token Development Company </span>
        </div>
      </div>
      <Whatis />
      <DevService />
      <ValueAdded />
      <TypesOf />
      <OurVarious />
      <TokenStandard />
      <BenifitsOf />
      <Exemplary />
      <Popular />
      {/* <DevelopToken /> */}
      {/* <Compliance />  */}
       {/* <WeWork /> */}
      {/* <SmartCont /> */}
      {/* <DecentralFinance /> */}
      <DevProcess />
      <SecurityConsider />
      <WhyChoose />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default CryptoToken
