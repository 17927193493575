import React from 'react'



class OurVarious extends React.Component {


  render() {

    return (
      <section className="icon pt-100 secureof cryptoken padremove">
        <div className="container">
          <h2 className="main-heading">Diverse Blockchain <span className='bluecolor'>We Work</span> On</h2>
          <p className='text-center'>Our Crypto token development services cover most of the widely used tokens, whether fungible or non-fungible. We have expertise in launching tokens according to the blockchain type you want. Here are the different token services we offer.</p>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/ethereum.png" alt="Ethereum Token Development" />Token Development on Ethereum
                  </div>
                <p className="pharagraph">We thrive on building Ethereum tokens on any token standard including ERC-20, ERC-721, ERC-223, ERC-777, ERC-998, ERC 1155. Our Ethereum token development company is specialized in creating highly secure Ethereum tokens.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/binance.png" alt='BNB Token Development' />
                Token Development on BNB
                </div>
                <p className="pharagraph">Create Binance smart chain-based Crypto tokens using token standards like BEP-20, and BEP-2 with us. Our BEP-20 tokens have improved features in vital areas like transaction speeds, lower transaction fees, and many more.</p>
              </div>
            </div> 
          </div>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/tron.png" alt='Tron Token Development' />
                Token Development on Tron
                </div>
                <p className="pharagraph">Launch scalable and highly compatible Tron tokens using popular token standards like TRC-20 and TRC-721. Our tron tokens facilitate rapid transactions and have a distinct framework since it is built on the proof-of-stake consensus algorithm.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/solana.png" alt='Solana Token Development' />
                Token Development on Solana
                </div>
                <p className="pharagraph">Create Crypto tokens on the Solana blockchain, which allows more than 50,000 transactions per second. As a premier Solana token development company, we work on the proof-of-history consensus algorithm and your tokens can easily grab a lot of investors.</p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv'>
                <div className='head3' style={{background: "#edf5ff", color: "#000"}}>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/waves.png" alt='Waves Token Development' />
                Waves Token Development
                </div>
                <p className="pharagraph">We, Coinsclone create Crypto tokens on the Waves blockchain that work on the proof-of-stake consensus algorithm. We have trained experts who have mastered token development on the Waves blockchain.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-token/polygon.png" alt='Polygon Token Development' />
                Polygon Token Development
                </div>
                <p className="pharagraph">Our polygon token development company can leverage your business to the top. We create Crypto tokens like Polygon Pegged tokens (Peggycoins) and DinoSwap etc, with advanced features using our blockchain experts.</p>
              </div>
            </div>
          </div>
          <a href="/contact-us/" className="bluebtn mt-2 m-auto d-table">Hire Our Crypto Token Developers</a>
        </div>
      </section>
    )
  }
}

export default OurVarious