import React from 'react'


const TokenStandard = () => {


  return (
    <section className="pt-100 mb-0 benifor standard">
      <div className='gray-bg'>
      <div className="container">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Token Development</span> Standards We Expertise At</h2>
          <div className="row">
                  <div className="d-lg-flex flex-wrap add-one">
                    <div className="square" >
                      <h3>ERC20 Token Development</h3>
                      <p className="pharagraph">
                      We create versatile, ERC20 tokens with our expert development services. The ERC20 tokens we create follow a standard protocol for seamless integration and exchange.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>ERC721 Token Development</h3>
                      <p className="pharagraph">
                      Our unique ERC721 tokens are developed with specialized services, perfect for digital art, collectibles, and gaming assets. Our ERC721 ensures each token is verifiable on Ethereum Blockchain.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>BEP20 Token Development</h3>
                      <p className="pharagraph">
                      We launch adaptable, BEP20 tokens with our tailored development solutions for efficient transactions and lower fees. Our BEP20 tokens are compatible with BNB’s decentralized ecosystem.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>BEP721 Token Development</h3>
                      <p className="pharagraph">
                      Our team of experts creates distinctive, BEP721 tokens with the latest trends and technologies. Our BEP721 ensures each asset is unique and secured on the BSC network.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>TRC20 Token Development</h3>
                      <p className="pharagraph">
                      With our specialized services, we develop TRC20 tokens offering highspeed and low-cost transactions. Our TRC20 tokens are perfect for digital assets and decentralized applications.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>TRC721 Token Development</h3>
                      <p className="pharagraph">
                      Our token developers are experts in creating unique TRC721, ideal for digital art and collectible assets. The TRC721 tokens we create ensure each token is distinctive and verifiable on TRON platform.
                      </p>
                    </div>
                  </div>
            </div>
          <div className='token-stand mw1030 mt-2 mt-md-4'>
                <p>ERC 223</p>
                <p>ERC 777</p>
                <p>ERC 998</p>
                <p>ERC 1155</p>
                <p>ERC 1400</p>
                <p>BEP 1155</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TokenStandard